import React from 'react';
import * as Sentry from '@sentry/browser';
import AppContext from '../context/app'
import TestQuestionAnswers from './TestQuestionAnswers'
import { createBrowserHistory } from 'history'
import { Icon, Button } from '@mui/material';

class TestQuestion extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { }
    this.history = createBrowserHistory();
    this.handleNext = this.handleNext.bind(this);
  }

  componentWillMount () {
    if (!this.context.trainingPage) {
      this.props.history.push('/home');
      return
    }
  }

  render () {
    return (
      <div id='question'>
        { this.context.trainingPage ?
          <section>

            <div className="flex-row question-content">
              <div className="flex-col question-container">
                { this.context.trainingPage ?
                  <TestQuestionAnswers context={this.context} question={this.context.trainingPage} />
                :
                  null
                }

                <div className="flex-row actions">
                  <Button disabled={!this.context.trainingPage.answered} onClick={this.handleNext} variant="outlined"><Icon className="fa fa-arrow-right" /> Next</Button>
                </div>
              </div>

              { this.context.trainingPage.imageUrl ? 
                <div className="flex-col image-container">
                  <img className="image" src={`/img/${this.context.trainingPage.imageUrl}`} alt="placeholder"></img>
                </div>
              :
                null
              }                    
            </div>
          </section>
        : null }
        </div>
    )
  }

  async handleNext(event) {
    try {
      let nextLocationIndex = this.context.locationIndex+1
      if (nextLocationIndex >= this.context.training.length){
        if (this.context.passed){
          await this.context.next()
          this.props.history.push(`/thankyou`);
        }
        else
          this.props.history.push(`/failed`);
      }
      else
        await this.context.next()

      window.scroll(0,0)
    }
    catch(err){
      console.log(err)
      Sentry.captureException(err);
      this.context.toastError('Error Saving Training')
    }
  }
}

export default TestQuestion
