import React from 'react';
import AppContext from '../context/app'
import { createBrowserHistory } from 'history'

import Page from '../components/Page'
import Title from '../components/Title'
import Question from '../components/Question'
import TestQuestion from '../components/TestQuestion'
import Video from '../components/Video'


import '../less/app.less'

class Training extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {}
    this.history = createBrowserHistory();
  }

  componentDidMount () {
    if (!this.context.training) {
      this.props.history.push({pathname: '/home'});
      return
    }
  }

  render () {
    return (
      <div id='training'>
        { this.context.trainingPage ?
          <section>
            { this.context.trainingPage.type === 'title' ?
              <Title {...this.props} context={this.context}></Title>
            : null }
            { this.context.trainingPage.type === 'video' ?
              <Video {...this.props} context={this.context}></Video>
            : null }
            { this.context.trainingPage.type === 'page' ?
              <Page {...this.props} context={this.context}></Page>
            : null }
            { this.context.trainingPage.type === 'question' ?
              <Question {...this.props} context={this.context}></Question>
            : null }
            { this.context.trainingPage.type === 'test' ?
              <TestQuestion {...this.props} context={this.context}></TestQuestion>
            : null }

          </section>
        :
          null
        }
        </div>
    )
  }

 
}

export default Training
