import React from 'react';
import AppContext from '../context/app'
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

class Progress extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { stepPositions: [], steps: [] };
  }

  


  componentDidMount () {
    if (!this.context.training){
      return
    }

    let stepPositions = []
    let steps = []
    this.context.training.forEach( (trainingPage, index) => {
      if (trainingPage.save) {
        let percentComplete = Math.ceil(((index+1)/(this.context.training.length-1))*100) || 0
        if (percentComplete > 100)
          percentComplete = 100
        stepPositions.push(percentComplete)
        steps.push(trainingPage)
      }
    })
    this.setState({stepPositions, steps})
  }

  render () {
    return (
      <div id='progress'>
          { this.context.user && this.context.user.completedSections ? 
          <ProgressBar
                percent={this.context.percentComplete}
                filledBackground="linear-gradient(to right, #f8f2ee, #a90004)"
                stepPositions={this.state.stepPositions}>
            
            { this.state.steps.map( (step, index) => {
                return (
                  <Step key={index} transition="scale">
                  {({ accomplished, index }) => (
                    <span className={`check-circle ${this.context.user.completedSections.find( (completedSection) => {return completedSection.sectionId === step.sectionId})  ? 'complete-check' : ''} `}>
                      <i className={`fa fa-check-circle ${this.context.user.completedSections.find( (completedSection) => {return completedSection.sectionId === step.sectionId})  ? 'complete-check' : ''} `}></i>
                    </span>
                  )}
                </Step>
    
                )
              })}
        </ProgressBar>
        : null }
      </div>
    )
  }

}

export default Progress
