import React, { useState, useContext, useEffect } from 'react';
import { Switch, Route, Redirect, BrowserRouter as Router, useLocation } from "react-router-dom";
import { getCookie } from 'react-use-cookie';

import userService from '../services/user';
import sectionService from '../services/section';
import systemService from '../services/system';

import Home from '../pages/Home';
import Training from '../pages/Training';
import ThankYou from '../pages/ThankYou';
import Failed from '../pages/Failed';

import AppError from '../components/AppError'
import Message from '../components/Message'
import Header from '../components/Header';
import Progress from '../components/Progress'

import ga from 'react-ga';
import AppContext from '../context/app'

import '../css/app.css'

export default function App(props) {
  const context = useContext(AppContext)
  const location = useLocation()

  const [loading, setLoading] = useState(true)
  const [currentSystem, setCurrentSystem] = useState()
  
  useEffect(() => {
    //act like you changed pages by going to top
    window.scroll(0, 0)   

    //Log in Analytics
    ga.set({ page: location.pathname });
    ga.pageview(location.pathname);

    //security
    if (!getCookie('clps'))
      window.location = 'https://login.clpsconsultants.com/'
    
  }, [location]);

  useEffect( () => {
    //Default page
    if (!getCookie('clps')) {
      window.location = 'https://login.clpsconsultants.com/'
    }
    else {
      let systemName = location.hash.split('#')[1]
      if (!systemName && !process.env.REACT_APP_NO_REDIRECT)
        window.location = 'https://login.clpsconsultants.com/'

      //load up data and goto default page
      let loadPromises = [
        new userService().get(systemName),
        new sectionService().get(systemName),
        new systemService().get(systemName)   
      ]
      Promise.all(loadPromises)
      .then( (loadResult) => {
        let user = loadResult[0];
        let sections = loadResult[1];
        let system = loadResult[2];

        if (!user){
          window.location = 'https://login.clpsconsultants.com/logout'
          return
        }

        console.log('logged in as: ', user.userId)
        ga.set({ user: user.userId });

        context.updateTraining(sections)
        context.updateUser(user)
        context.updateSystem(system)
        setCurrentSystem(system)
        setLoading(false)

        let completeSectionsStatus = 'Not Started';
        if( user.completedSections.length >= system.numberOfSections)
          completeSectionsStatus = 'Completed'

        if(user.completedSections.length >= 1 && user.completedSections.length < sections.length) {    
          //get the next section you have not completed
          let jumpToSectionIndex = sections.findIndex( s => !user.completedSections.find( (completedSection) => { return completedSection.sectionId === s.sectionId }) )
          context.jumpToSection(sections[jumpToSectionIndex-1].sectionId)
          completeSectionsStatus = 'In Progress';
        }

        if (location.pathname === '/') {
          if (completeSectionsStatus !== 'In Progress' )
            window.location = `/home/#${system.system}`

          if (completeSectionsStatus === 'In Progress')
            window.location = `/training/#${system.system}`
        }
      })
      .catch( (err) => {
        console.log(err);
        if (!process.env.REACT_APP_NO_RESET_ON_ERROR)
          window.location = 'https://login.clpsconsultants.com/'
      })
    }
  },[])

  return (
    <Router>
      { !loading ? 
      <div>
        <Message/>
        <Header/>    
        <div className="app">
            <section>
              <Progress />
            </section>
          <section className="content">
            <AppError>
              <Switch>
                <Route path="/home" component={Home}/>
                <Route path="/training" component={Training}/>
                <Route path="/thankyou" component={ThankYou}/>
                <Route path="/failed" component={Failed}/>
                <Redirect path="/" to={`/home/#${currentSystem.system}`} />
              </Switch>
            </AppError>
          </section>
        </div>
      </div>
      : null }
    </Router>
  )
}