import React from 'react';
import AppContext from '../context/app'
import '../less/app.less'
import { FormControl, RadioGroup, Radio, FormControlLabel} from '@mui/material';

class TestQuestionAnswers extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {};
  }

  render () {
    return (
      <div className='question'>
        { this.props.question.correct != null ?
          <div className="answer-response">
              { this.props.question.correct ?
                <span className='correct-answer'>Correct! <span dangerouslySetInnerHTML={{__html: this.props.question.rightChoiceText}}></span></span>
                :
                <span className='wrong-answer'>Incorrect, the correct answer is <span dangerouslySetInnerHTML={{__html: this.props.question.rightChoiceText}}></span></span>
              } 
          </div>
          : null
        }

        <div dangerouslySetInnerHTML={{__html: this.props.question.text}}>
        </div>

        <div className='answer-area'>
        { this.props.question ?
            <FormControl component="fieldset">
              <RadioGroup>
                { this.props.question.choices.map( (choice) => {
                  return (
                    <div key={choice.questionChoiceId}>
                      <div className='question-choice'>
                        <FormControlLabel value={choice.questionChoiceId} control={<Radio required disabled={!!this.props.question.answered} />} label={choice.answerText} onChange={this.handleAnswerChange.bind(this)}/>
                      </div>
                    </div>
                  );  
                })}
              </RadioGroup>
            </FormControl>
          :
            null
          }
        </div>

      </div>
    )
  }

  handleAnswerChange(event) {
    let correct = this.props.question.rightQuestionChoiceId !== +event.target.value ? false : true;
    this.context.setQuestionAnswer({ sectionId: this.context.trainingPage.sectionId , questionId: this.props.question.questionId, correct });
    this.context.addTestAnswers(correct)
  }

}

export default TestQuestionAnswers
