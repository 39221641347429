import React from 'react';
import AppContext from '../context/app'
import { createBrowserHistory } from 'history'
import { Icon, Button } from '@mui/material';

class Title extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {}
    this.beginNow = this.beginNow.bind(this);
    this.history = createBrowserHistory();
  }

  componentWillMount () {
    if (!this.context.trainingPage) {
        return
    }  
  }

  beginNow () {
    this.context.next()
    if (this.context.locationIndex+1 >= this.context.training.length) {
      this.props.history.push(`/thankyou`);
    }
  }

  render () {
    return (
      <div id='title'>
        <div>
            {this.context.trainingPage ?
              <h1>{this.context.trainingPage.name}</h1>
            : null }
            <div className="flex-row actions">
              <Button onClick={this.beginNow} variant="outlined"><Icon className="fa fa-arrow-right" /> Next</Button>
            </div>
        </div>
      </div>
    )
  }

}

export default Title
