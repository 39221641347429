export default class sectionService {
  get(system) {
    return fetch(`/api/section/get?system=${system || ''}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  files(system) {
    return fetch(`/api/section/files?system=${system}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  downloadFile(file) {
    window.open(`/api/section/downloadFile?file=${file}`)
  }

  downloadSections() {
    window.open('/api/section/download')
  }
}