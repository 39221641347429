export default class LocalStorageService {
    constructor() {
      this.storage = window.localStorage;
    }
  
    /**
     * Get a value from local storage with an optional expiration time
     * @param {string} key - The key to retrieve from local storage
     * @returns {*} - The value retrieved from local storage, or null if it doesn't exist or has expired
     */
    get(key) {
      const item = this.storage.getItem(key);
      if (!item) {
        return null;
      }
  
      const data = JSON.parse(item);
  
      // If an expiration time was provided and the data has expired, remove it from storage and return null
      if (data.expirationTime && Date.now() > data.timestamp + (data.expirationTime * 1000)) {
        this.remove(key);
        return null;
      }
  
      return data.value;
    }
  
    /**
     * Save a value to local storage with an optional expiration time
     * @param {string} key - The key to save the value under in local storage
     * @param {*} value - The value to save
     * @param {number} expirationTime - The number of seconds until the value expires
     */
    set(key, value, expirationTime = null) {
      const data = {
        value: value,
        timestamp: Date.now()
      };
  
      if (expirationTime) {
        data.expirationTime = expirationTime;
      }
  
      this.storage.setItem(key, JSON.stringify(data));
    }
  
    /**
     * Remove a value from local storage
     * @param {string} key - The key to remove the value for
     */
    remove(key) {
      this.storage.removeItem(key);
    }

    clear(){
      this.storage.clear()
    }
  }