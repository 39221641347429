import React from 'react';
import AppContext from '../context/app'
import { Paper, Icon, Button } from '@mui/material';
import localStorage from '../services/localstorage'

class Failed extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { }
  }

  async componentDidMount () {
    let numberOfFails = new localStorage().get('numberOfFails') || 0
    numberOfFails++
    //2 fails, clear the local storage and require rewatch the video
    if(numberOfFails >= 2)
      new localStorage().clear()
    else
    new localStorage().set('numberOfFails',numberOfFails, 1000 * 60 * 60 * 24 * 180)
    this.setState({numberOfFails})
  }
  
  //You can print a copy of your Certificate here.
  render () {
    return (
      <div id='thankyou'>
        <section className='message-box-container'>
          <div className='headline'>In-Complete</div>
          <Paper className='message-box'>
              <div className="complete-message">
                { this.state.numberOfFails >= 2 ?
                  <span>You must re-watch the video and re-take the test</span>
                :
                  <span>Please re-take the test</span>
                }
              </div>
          </Paper>

          <div className='flex-row logout-container'>
            <div className='flex-col'>
            <a href="https://login.clpsconsultants.com">
              <Button variant="outlined"><Icon className="fa fa-rotate-left" />Retry</Button>
            </a>
            </div>
          </div>

        </section>
      </div>
    )
  }
}


export default Failed
