import React from 'react';
import AppContext from '../context/app'
import { createBrowserHistory } from 'history'
import { Icon, Button } from '@mui/material';

import '../less/app.less'

class Page extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {}
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.history = createBrowserHistory();
  }

  componentDidMount () {
    if (!this.context.trainingPage) {
      this.props.history.push({pathname: '/home'});
      return
    }
  }


  handleBack() {
    this.context.back()    
    setTimeout(()=> { window.scroll(0,0)}, 200 )
  }

   //handle Next
  async handleNext(event) {
    let nextLocationIndex = this.context.locationIndex+1
    await this.context.next()
    //if going past the last page
    if (nextLocationIndex >= this.context.training.length)
      this.props.history.push(`/thankyou`);
    window.scroll(0,0)
  }



  render () {
    return (
      <div id='page'>
        { this.context.trainingPage ?
          <section>
            <div className="flex-row page-content">
              <div className="flex-col page-text-container">
                <p className="page-text" dangerouslySetInnerHTML={{__html: this.context.trainingPage.text}}>
                </p>
                <div className="flex-row actions">
                  <Button className="back" onClick={this.handleBack} variant="outlined"><Icon className="fa fa-arrow-left" /> Back</Button>
                  <Button onClick={this.handleNext} variant="outlined"><Icon className="fa fa-arrow-right" /> Next</Button>
                </div>
              </div>

              { this.context.trainingPage.imageUrl ? 
                <div className="flex-col image-container">
                  <img className="image" src={`/img/${this.context.trainingPage.imageUrl}`} alt="security"></img>
                </div>
              :
                null
              }                    
            </div>
          </section>
        :
          null
        }
        </div>
    )
  }

 
}

export default Page
