import React from 'react';
import AppContext from '../context/app'
import { createBrowserHistory } from 'history'
import sectionService from '../services/section';
import { Icon, Button } from '@mui/material';

import '../less/app.less'

class Home extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { files: [] }
    this.sectionService = new sectionService();
    this.beginNow = this.beginNow.bind(this);
    this.downloadFile = this.downloadFile.bind(this)
    this.history = createBrowserHistory();
  }

  async componentWillMount () {
    if (this.context.user && this.context.system && this.context.user.completedSections.length === this.context.system.numberOfSections) {
      let files = await this.sectionService.files(this.context.system.system)
      this.setState({files})
    }
  }

  beginNow () {
    //begin first module
    this.props.history.push(`/training`);
  }

  downloadFile(file){
    file.downloadedTimeStamp = new Date()
    this.setState({files: this.state.files})
    this.sectionService.downloadFile(file.location)
  }

  render () {
    return (
      <div id='home'>
        { !this.props.location.search.length && this.context.user && this.context.system && this.context.user.completedSections.length < this.context.system.numberOfSections ?
          <div>
            <h2>Welcome to {this.context.system.name} Training</h2>
            <p dangerouslySetInnerHTML={{__html: this.context.system.introductionText }}></p>
            <div className="flex-row flex-center">
              <Button onClick={this.beginNow} variant="outlined"><Icon className="fa fa-arrow-right" /> BEGIN</Button>
            </div>
          </div>
        :
          <div>
            { this.context.user && this.context.user.completedSections.length === this.context.system.numberOfSections ? 
            <div>
              <h2>Thank you for completing {this.context.system.name} Training</h2>
              
              <div className="flex-row actions">
                { (this.context.system.system === 'activeShooter' || this.context.system.system === 'threatTraining') ?
                  <div className="flex-col">
                    { this.state.files.map( (file, index) => {
                      return (
                        <a download onClick={(e) => this.downloadFile(file)}>                            
                          <Button onClick={this.beginNow} variant="outlined"> { file.downloadedTimeStamp ? <Icon className="fa fa-check-circle complete-check" /> : null } <Icon className="fa fa-download" /> {file.title}</Button>
                        </a>
                      )
                    })}
                  </div>
                : null }

                <div className="flex-col">
                  <Button onClick={this.beginNow} variant="outlined"><Icon className="fa fa-eye" /> Review Training</Button>
                </div>
              </div>
            </div>
            : null } 
          </div>
        }
      </div>
    )
  }

}

export default Home
