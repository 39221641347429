export default class userService {
  get(system) {
    return fetch(`/api/user/get?system=${system}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => response.json())
  }

  sectionVideoComplete(videoId){
    console.log(`Video complete call ${JSON.stringify({ videoId })}`)
    return fetch('/api/user/sectionVideoComplete', { 
      method: 'POST', 
      body: JSON.stringify({ videoId }), 
      mode: 'cors', 
      credentials: 'include', 
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        console.log(response)
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  sectionComplete(sectionId, system){
    console.log(`section complete call ${JSON.stringify({ sectionId, system })}`)
    return fetch('/api/user/sectionComplete', { 
      method: 'POST', 
      body: JSON.stringify({ sectionId, system }), 
      mode: 'cors', 
      credentials: 'include', 
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      console.log(response)
      if (!response.ok) {
        console.log(response)
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }
}