import React, { Suspense }  from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';

import ga from 'react-ga';
import * as Sentry from '@sentry/react';

import App from './pages/App';
import Loader from './components/Loader'
import AppError from './components/AppError'
import AppProvider from './context/AppProvider'

//App Load
if (process.env.REACT_APP_SENTRY_KEY) {
  ga.initialize('UA-83594280-2',{ debug: false });
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY
  });
}

class Root extends React.Component {
  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <div>
        <AppError>
          <Suspense fallback={<Loader />}>
            <CookiesProvider>
              <AppProvider>
                <Router>
                  <App/>
                </Router>
              </AppProvider>
            </CookiesProvider>
          </Suspense>
        </AppError>
      </div>
    )
  }
}
 
//Render the app
ReactDOM.render(<Root/>, document.getElementById('app'));