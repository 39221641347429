import React from 'react'
import AppContext from './app'

import userService from '../services/user'

class AppProvider extends React.Component {
    constructor(props) {
        super(props);

        this.updateUser =  (user) => {
            this.setState( { user })
        }

        this.updateSystem = (system) => {
            this.setState({ system })
        }

        this.updateTraining =  (sections) => {
            let training = []
            sections.forEach( (section) => {
                training.push({ type: 'title', ...section })

                let videos = section.videos.map( (v) => { return { type: 'video', ...v } })
                training = training.concat(videos)
                let reading = section.pageText.map( (p) => { return { type: 'page', ...p } })
                training = training.concat(reading)
                let questions = section.questions.filter(q => !q.isTest).map( (q) => { return { type: 'question', ...q } })
                training = training.concat(questions)
                let test = section.questions.filter(q => q.isTest).map( (q) => { return { type: 'test', ...q } })
                training = training.concat(test)
                
                //update last page to save
                training[training.length-1].save = true
                training[training.length-1].sectionId = section.sectionId
            })
            this.setState( { training })
            this.locationIndexUpdated(0)
        }

        this.setQuestionAnswer = (questionAnswer) => {
            let trainingPage = this.state.trainingPage
            trainingPage.correct = questionAnswer.correct;
            trainingPage.answered = true
            this.setState({ trainingPage });
        }

        this.addTestAnswers = (testAnswer) => {
            let testAnswers = this.state.testAnswers || []
            testAnswers.push(testAnswer)
            this.setState( { testAnswers })
            let numberCorrectAnswers = testAnswers.filter( t => !!t).length
            let percentageCorrect = Math.round(numberCorrectAnswers/testAnswers.length) * 100
            if (percentageCorrect >= 70)
                this.setState({ passed: true })
            else
                this.setState({ passed: false })
        }

        this.locationIndexUpdated = (locationIndex) =>{
            let percentComplete = locationIndex ? Math.ceil((locationIndex/(this.state.training.length-1))*100) : 0
            if (percentComplete > 100)
                percentComplete = 100
            let trainingPage = this.state.training[locationIndex]
            this.setState({trainingPage, locationIndex, percentComplete})
        }

        this.next = async () => {
            if (this.state.trainingPage.save) {
                let completeResult = await new userService().sectionComplete(this.state.trainingPage.sectionId, this.state.system.system)
                let user = this.state.user;
                user.completedSections.push({ sectionId: this.state.trainingPage.sectionId })
                this.updateUser(user)
                this.setState({ nextSystem: completeResult.nextSystem })
            }
            //if not going out of bounds, advance
            if (this.state.locationIndex+1 < this.state.training.length)
                this.locationIndexUpdated(this.state.locationIndex+1)
        }

        this.back = () => {
            this.locationIndexUpdated(this.state.locationIndex-1)
        }

        this.jumpToSection = (sectionId) => {
            //jump to the start of the next section
            this.locationIndexUpdated(this.state.training.findIndex( t => t.sectionId === sectionId+1 ))
        }

        this.toastSuccess = (message) =>{
            let toast = { type: 'success', message }
            this.setState( { toast })
            setTimeout( () => {
                this.setState( { toast: null })
            },2000 )
        }

        this.toastError = (message) =>{
            let toast = { type: 'error', message }
            this.setState( { toast })
            setTimeout( () => {
                this.setState( { toast: null })
            },2000 )  
        }

        this.removeToast = ()=>{
            this.setState( { toast: null })
        }
        
        this.state = {
            locationIndex: 0,
            progress: 0,
            percentComplete: 0,
            training: [],
            trainingPage: null,
            updateUser: this.updateUser,
            updateSystem: this.updateSystem,
            updateTraining: this.updateTraining,
            next: this.next,
            back: this.back,
            jumpToSection: this.jumpToSection,
            setQuestionAnswer: this.setQuestionAnswer,
            addTestAnswers: this.addTestAnswers,
            toastSuccess: this.toastSuccess,
            toastError: this.toastError,
            removeToast: this.removeToast

        }
    }

    render () {
        return (
            <AppContext.Provider value={this.state}>
                {this.props.children}
            </AppContext.Provider>
        )
    }
}
export default AppProvider