import React from 'react';
import AppContext from '../context/app'
import '../less/app.less'
import qs from 'qs' 
import { createBrowserHistory } from 'history'
import { Paper, Icon, Button } from '@mui/material';

import sectionService from '../services/section';

class ThankYou extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { files: [], queryString: qs.parse(window.location.search.slice(1)) }
    this.history = createBrowserHistory();
    this.sectionService = new sectionService();
  }

  async componentDidMount () {
    if (this.context.system.system === 'activeShooter' || this.context.system.system === 'threatTraining') {
      let files = await this.sectionService.files(this.context.system.system)
      this.setState({files})
    }
  }
  
  //You can print a copy of your Certificate here.
  render () {
    return (
      <div id='thankyou'>
        <section className='message-box-container'>
          <div className='headline'>Complete</div>
          <Paper className='message-box'>
              <div className="complete-message">
                Thank you for completing this course.  The appropriate school administrator was notified of your completion.  
                { !this.context.nextSystem ? <span>  Your certificate will be emailed to you shortly.</span> : null }
              </div>
          </Paper>

          { this.state.files.map( (file, index) => {
            return (
              <div className='download-container'  key={index}>
                <a classname="download" download href={file.location}>
                  <Button variant="outlined"><Icon className="fa fa-download" /> {file.title}</Button>
                </a>
              </div>
            )
          })}

          <div className='flex-row logout-container'>
            <div className='flex-col'>
            { this.context.nextSystem ?
              <a href={`https://training.clpsconsultants.com/#${this.context.nextSystem}`}>
                <Button variant="outlined"><Icon className="fa fa-arrow-right" /> Continue to next training</Button>
              </a>
            :
              <a href="https://login.clpsconsultants.com">
                <Button variant="outlined"><Icon className="fa fa-arrow-right-from-bracket" /> Logout</Button>
              </a>
            }
            </div>
          </div>

        </section>
      </div>
    )
  }
}


export default ThankYou
