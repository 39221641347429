export default class systemService {
    get(system) {
      return fetch(`/api/system/get?system=${system || ''}`, { method: 'GET', mode: 'cors', credentials: 'include' })
      .then(response => { 
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json(); 
      })
      .catch( (error) => {
        console.error(error);
        throw Error(error);
      })
    }  
  }